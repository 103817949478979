<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <!-- kriterij iskanja -->
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md12 lg12 xl12>
                    <v-card>
                            <v-card-title>
                                    <span class="subtitle-1"><v-icon left>mdi-filter</v-icon> Filter results</span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="card1 = !card1">
                                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">
                                <!-- sales locations -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Sales locations</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_locations"
                                            :items="locations"
                                            label="Select locations"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="cashiers"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="No data to display"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- cashiers -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Cashiers</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_cashiers"
                                            :items="cashiers"
                                            label="Select cashiers"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="No data to display"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            <v-divider v-show="card1"></v-divider>
                            <span v-if="card1">
                                <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn   @click="search()" color="secondary" dark  block style=""><v-icon left>mdi-magnify</v-icon>Search</v-btn><br>
                                <v-btn   @click="exportData()" color="secondary" dark  block style=""><v-icon left>mdi-export</v-icon>Export&nbsp;</v-btn>
                                </v-card-text>
                                <v-card-text v-else>
                                    <v-btn  @click="search()" color="secondary" dark small style="margin-right:10px;"><v-icon left>mdi-magnify</v-icon>Search</v-btn>
                                    <v-btn  @click="exportData()" color="#5E35B1" dark small><v-icon left>mdi-export</v-icon>Export&nbsp;</v-btn>
                                </v-card-text>
                            </span>
                        </v-card>
                </v-flex>
            </v-layout>

            <br>

            <!-- seznam naprav s podatki o zadnjem servisu itd .. -->
            <cashier-maintenances-data-table
            :settings="dataTableCashierMaintanenceSettings"
            :queryParameters="queryParameters"
            ></cashier-maintenances-data-table> 

            <br><br>

            <!-- seznam opravljenih servisov -->
            <maintenances-data-table
            :settings="dataTableMaintenanceSettings"
            :queryParameters="queryParameters"
            ></maintenances-data-table> 

            <data-table-toggle-columns
            :dataTableId="dataTableCashierMaintanenceSettings.id"
            :columns="dataTableCashierMaintanenceSettings.headers"
            dataTableColumnsMenuId="toggle-maintenance-devices-list-columns"

            ></data-table-toggle-columns>

            <data-table-toggle-columns
            :dataTableId="dataTableMaintenanceSettings.id"
            :columns="dataTableMaintenanceSettings.headers"
            dataTableColumnsMenuId="toggle-maintenances-list-columns"

            ></data-table-toggle-columns>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate, getAppSettingsFromLocalStorage } from '@/helpers/utilities'
import { isMobileDevice } from '@/helpers/utilities.js'
import { addExportToQueue } from '@/helpers/services.js'
const MaintenancesDataTable = () => import('@/components/MaintenancesDataTable.vue')
const CashierMaintenancesDataTable = () => import('@/components/CashierMaintenancesDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')

export default {
    components: {
        MaintenancesDataTable,
        CashierMaintenancesDataTable,
        DataTableToggleColumns
    },

    data: () => ({
        card1: true,
        card2: true,
        card3: true,

        interval1: null,

        cashiers: [],
        locations: [],

        selected_cashiers: [],
        selected_locations: [],

        dataTableCashierMaintanenceSettings: {
            id: 'dtMaintenanceDevices',
            title: 'Devices',
            sortColumn: ['name'],
            sortDirection: ['asc'],
            progressHeight: 3,
            headers: [
                { text: "ID", value: "id", class: "text-xs-left", visibility: true },
                //{ text: "Device", value: "name", class: "text-xs-left", visibility: true },
                { text: "Sales location", value: "business_premise_name", class: "text-xs-left", visibility: true },
                { text: "Cashier", value: "cashier_nr", class: "text-xs-left", visibility: true },
                //{ text: "All entrances", value: "telemetry.all_entrances", class: "text-xs-left", visibility: true },
                //{ text: "Entrances since last maintenance", value: "telemetry.entrances_since_last_maintenance", class: "text-xs-left", visibility: true },
                { text: "All entrances", value: "all_entrances", class: "text-xs-left", visibility: true },
                { text: "Entrances since last maintenance", value: "entrances_since_last_maintenance", class: "text-xs-left", visibility: true },
                { text: "Last maintenance", value: "telemetry.last_maintenance.maintenance_timestamp", class: "text-xs-left", visibility: true, sortable: false },
                { text: "Actions", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true}
            ],
            updateInterval: true,
            rowsPerPage: 10,
            endpoint: 'v1/maintenances/devices',
            editItem: {
                route: null,
                routeParameterValue: null
            },
            deleteItem: {
                vuexAction: null,
                replaceStrWithColumnValue: null,
                confirmationText : null,
                dialogConfirmSuccessText: null,
                dialogConfirmFailText: null
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: true,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_maintenance_devices',
                totalItems : {
                    event: ''
                },
                search: 'dt-maintenance-devices-search',
                toggleColumnsId: 'toggle-maintenance-devices-list-columns'
            }


        },

        dataTableMaintenanceSettings: {
            id: 'dtMaintenances',
            title: 'Maintenances',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "ID", value: "id", class: "text-xs-left", visibility: true },
                { text: "Sales location", value: "business_premise_name", class: "text-xs-left", visibility: true },
                { text: "Cashier", value: "cashier_nr", class: "text-xs-left", visibility: true },
                { text: "Servicemen", value: "servicemen", class: "text-xs-left", visibility: true },
                { text: "Maintenance time", value: "maintenance_timestamp", class: "text-xs-left", align: "left", visibility: true , sortable: true },
                { text: "Entrances since last maintenance", value: "entrances", class: "text-xs-left", visibility: true },
                { text: "Tag", value: "tag", class: "text-xs-left", visibility: true },
                { text: "Actions", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true}
            ],
            updateInterval: true,
            rowsPerPage: 10,
            endpoint: 'v1/maintenances',
            editItem: {
                route: null,
                routeParameterValue: null
            },
            deleteItem: {
                vuexAction: 'DELETE_MAINTENANCE',
                replaceStrWithColumnValue: 'id',
                routeParameterValue: 'uuid',
                confirmationText : "Are you sure you want to delete maintenance number #{str_to_replace} ?",
                dialogConfirmSuccessText: 'Maintenance {str_to_replace} deleted.',
                dialogConfirmFailText: 'An error occurred while deleting the maintenance {str_to_replace}. Try again.'
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_maintenances',
                totalItems : {
                    event: ''
                },
                search: 'dt-maintenances-search',
                toggleColumnsId: 'toggle-maintenances-list-columns'
            }


        }
    }),
    
    watch: {
        selected_locations(value) {
            //window.console.log(value)
        },

        selected_cashiers(value) {
            //window.console.log(value)

        },

        card1: {
            handler(value) {
                //window.console.log("Telemetry card1: " + value)
                this.$store.commit("APP_SETTING", {
                    setting: "maintenances",
                    key: "filter_results_open",
                    value: value
                })
            },

            //immediate: true
        }
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        queryParameters() {

            let parameters = {
                selectedCashiers: this.selected_cashiers.map(item => item.uuid),
                selectedLocations: this.selected_locations.map(item => item.id)
            }

            
            let selectedLocationCashiers = [];
            if(this.selected_locations.length > 0) {
                this.selected_locations.forEach(selectedLocation => {
                    selectedLocation.cashiers.forEach(cashier => {
                        selectedLocationCashiers.push(cashier.uuid)
                    })
                })
            }

            parameters.selectedLocationCashiers = selectedLocationCashiers;
            
            return parameters;
        },
    },

    methods: {
        search() {
            let vm = this
            setTimeout(() => {
                EventBus.$emit(this.dataTableCashierMaintanenceSettings.eventBus.refreshDataTable, vm.queryParameters);
                EventBus.$emit(this.dataTableMaintenanceSettings.eventBus.refreshDataTable, vm.queryParameters);
            }, 250)
        },

        exportData() {
            window.console.log("exporting data ...");
            let parameters = {
                selectedCashiers: this.selected_cashiers.map(item => item.uuid)
            }

            let selectedLocationCashiers = [];
            if(this.selected_locations.length > 0) {
                this.selected_locations.forEach(selectedLocation => {
                    selectedLocation.cashiers.forEach(cashier => {
                        selectedLocationCashiers.push(cashier.uuid)
                    })
                })
            }

            parameters.selectedLocationCashiers = selectedLocationCashiers;
            
            addExportToQueue('maintenances', parameters);
        },

        displayResults() {
            let vm = this
            setTimeout(() => {
                EventBus.$emit(this.dataTableCashierMaintanenceSettings.eventBus.refreshDataTable, vm.queryParameters);
                EventBus.$emit(this.dataTableMaintenanceSettings.eventBus.refreshDataTable, vm.queryParameters);
            }, 280)
        },

        setParametersData() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.dispatch('PARAMETERS_DATA')
            .then(response => {
                //console.log(response)
                //console.log(response.data)
                this.locations = response.data.businessPremises
                this.cashiers = response.data.cashiers

                //vm.displayResults();
            })
            .catch(error => {
                console.error("### setParametersData ###");
                console.log(error)
            })
            .finally(() => {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)  
                }, 700)
            })
        }
    },

    created() {
        var vm = this
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 

        vm.card1 = vm.$store.getters.appSettings.maintenances.filter_results_open

        /*if(isMobileDevice(this)) {
            this.card1 = false
        }*/

        this.setParametersData();

        this.interval1 = setInterval(() => {
            vm.setParametersData()
        }, process.env.VUE_APP_DATA_TABLES_REFRESH_INTERVAL || 180000)

        let ipp1 = getAppSettingsFromLocalStorage({
            setting: "dt_cashier_maintenances",
            key: "items_per_page"
        })

        if(ipp1 !== null) {
            this.dataTableCashierMaintanenceSettings.rowsPerPage = ipp1
        }


        let ipp2 = getAppSettingsFromLocalStorage({
            setting: "dt_maintenances",
            key: "items_per_page"
        })

        if(ipp2 !== null) {
            this.dataTableMaintenanceSettings.rowsPerPage = ipp2
        }
    },

    mounted() {

    },

    beforeDestroy() {
        EventBus.$off(this.dataTableCashierMaintanenceSettings.id)
        EventBus.$off(this.dataTableCashierMaintanenceSettings.eventBus.refreshDataTable)
        EventBus.$off(this.dataTableCashierMaintanenceSettings.eventBus.search)

        EventBus.$off(this.dataTableMaintenanceSettings.id)
        EventBus.$off(this.dataTableMaintenanceSettings.eventBus.refreshDataTable)
        EventBus.$off(this.dataTableMaintenanceSettings.eventBus.search)

        this.$store.commit('SET_SEARCH', '');
        this.$store.commit('SET_SEARCH_FIELD_STATE', false);
        this.$store.commit('SET_MOBILE_SEARCH', false)
    }
}

</script>

<style scoped>

</style>